import React from "react";
import {Helmet} from "react-helmet";

const imgPhone1 = "/images/iphone1.png";
const imgPhone2 = "/images/iphone2.png";

export default function MobileApp() {
  return (
      <div className="App-content">

        <Helmet>
          <title>2B0 Mobile App</title>
          <link rel="canonical" href="https://2b0.io/mobile_app" />
          <meta name="description" content="The 2B0 mobile app for iOS and Android is part of the 2B0 sustainability as a platform (SusP) ecosystem, giving you rewards for recycling." />
        </Helmet>

        <div className="section-top-padding"/>
        <p>&nbsp;</p>
        <h1><code>2B0</code>&nbsp;Mobile App</h1>
        <div className="phone-images">
          <img className="phone-images" alt="iPhone Screen 1" src={imgPhone1} />
          <img className="phone-images" alt="iPhone Screen 2" src={imgPhone2} />
        </div>
        <p className="left"><span className="small">The&nbsp;<code>2B0</code>&nbsp;mobile app for iOS and Android is part of the&nbsp;<code>2B0</code>&nbsp;sustainability as a platform (SusP) ecosystem, giving you rewards for recycling &mdash; while helping you to reduce the amount of material you need to recycle.</span></p>
        <p>&nbsp;</p>
        <p className="left"><span className="small">When integrated with the<a href="/recycling_robot">&nbsp;<code>2B0</code>&nbsp;recycling robot</a>, the&nbsp;<code>2B0</code>&nbsp;mobile app will provide real-time analysis of your recycling habits and recommendations for methods to reduce waste.</span></p>
      </div>
  );
}
