import React, { useState } from "react";
import {Helmet} from "react-helmet";

export default function ContactUs() {
  const [formEmail, setFormEmail] = useState('');
  const [formFirstname, setFormFirstname] = useState('');
  const [formLastname, setFormLastname] = useState('');
  const [formComments, setFormComments] = useState('');

  const submitForm = () => {
    document.getElementById("mc-embedded-subscribe-form").submit();
  };

  const changeInput = (e) => {
    if (e.target.id === "mce-EMAIL") {
      setFormEmail(e.target.value);
    } else if (e.target.id === "mce-FNAME") {
      setFormFirstname(e.target.value);
    } else if (e.target.id === "mce-LNAME") {
      setFormLastname(e.target.value);
    } else if (e.target.id === "mce-MMERGE5") {
      setFormComments(e.target.value);
    }
  }

  return (
      <div className="App-content">

        <Helmet>
          <title>Contact Us</title>
          <link rel="canonical" href="https://2b0.io/contact_us" />
          <meta name="description" content="Contact information and form for 2B0 customers, investors and students." />
        </Helmet>

        <div className="section-top-padding"/>
        <p>&nbsp;</p>
        <h1>Information Subscription</h1>
        <div id="mc_embed_signup">
          <form action="https://2b0.us2.list-manage.com/subscribe/post?u=90f9911ee8033ff225054edaa&amp;id=0be5e342a8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
            <div id="mc_embed_signup_scroll">
              <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span>
                </label>
                <input type="email" onChange={changeInput} value={formEmail} name="EMAIL" className="mc-field-group required email" id="mce-EMAIL"/>
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">First Name </label>
                <input onChange={changeInput} className="mc-field-group" type="text" value={formFirstname} name="FNAME" id="mce-FNAME"/>
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-LNAME">Last Name </label>
                <input onChange={changeInput} type="text" value={formLastname} name="LNAME" className="mc-field-group" id="mce-LNAME"/>
              </div>
              <div className="mc-field-group input-group">
                <strong>Please select one of the following options to tell us who you are:</strong>
                <ul className="mc-field-group">
                  <li><input type="radio" value="Investor" name="MMERGE3" id="mce-MMERGE3-0"/><label
                    htmlFor="mce-MMERGE3-0">Investor</label></li>
                  <li><input type="radio" value="Consumer" name="MMERGE3" id="mce-MMERGE3-1"/><label
                    htmlFor="mce-MMERGE3-1">Consumer</label></li>
                  <li><input type="radio" value="Student" name="MMERGE3" id="mce-MMERGE3-2"/><label
                    htmlFor="mce-MMERGE3-2">Student</label></li>
                </ul>
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-MMERGE5">Comments </label>
                <br clear="all" />
                <textarea rows="4" cols="60" onChange={changeInput} type="text" value={formComments} name="MMERGE5" className="mc-field-group" id="mce-MMERGE5" />
              </div>
              <div id="mce-responses" className="clear">
                <div className="response" id="mce-error-response" style={{display: "none"}} />
                <div className="response" id="mce-success-response" style={{display: "none"}} />
              </div>
              <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_90f9911ee8033ff225054edaa_0be5e342a8" tabIndex="-1" value=""/></div>
              <div className="clear">
                <button className="primary" onClick={submitForm}>Submit Form</button>
              </div>
            </div>
          </form>
          <p>&nbsp;</p>
          <h1>Contact Information</h1>
          <p><span className="small">General email inquiries: <a href="mailto:info@2b0.io">info@2b0.io</a></span></p>
          <p><span className="small">Investor email inquiries: <a href="mailto:investor@2b0.io">investor@2b0.io</a></span></p>
          <p><span className="small">Media email inquiries: <a href="mailto:media@2b0.io">media@2b0.io</a></span></p>
        </div>
      </div>
  );
}
