import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import AppContext from "../context/AppContext";

export default function Podcasts() {
  const context = useContext(AppContext);
  const imgExt = (context.isSafari) ? "jpg" : "webp";

  return (
    <div className="App-content">

      <Helmet>
        <title>2B0 Podcasts</title>
        <link rel="canonical" href="https://2b0.io/podcasts" />
        <meta name="description" content="2B0 podcasts" />
      </Helmet>

      <div className="section-top-padding"/>
      <div className="text-content">
        <h1>2B0 (To Be Zero) Podcasts</h1>

        <img className="podcasts" alt="Toasted Earth Podcast" src={"/images/podcast_toasted_earth2." + imgExt} />
        <a href="https://podcasts.apple.com/podcast/id1569429500" target="_new" rel="noopener noreferrer"><img className="podcasts-icon" alt="Listen on Spotify" src="/images/podcasts_apple.svg" /></a>
        <a href="https://open.spotify.com/show/20Np7ShJwcQMLcfgObg09W" target="_new" rel="noopener noreferrer"><img className="podcasts-icon" alt="Listen on Spotify" src="/images/podcasts_spotify.svg" /></a>
        <a href="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xNzIzNjA1LnJzcw==" target="_new" rel="noopener noreferrer"><img className="podcasts-icon" alt="Listen on Spotify" src="/images/podcasts_google.svg" /></a>
        <p className="small left">2B0 CTO and co-founder Steve Peer speaks with Michelle Cunningham from <a href="https://www.toastedearth.com/" target="_new" rel="noopener noreferrer">Toasted Earth</a> about obstacles to recycling, privacy and fighting climate change with the 2B0 recycling robot.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>

        <img className="podcasts" alt="Recycle Smart Podcast" src={"/images/podcast_recycle_smart." + imgExt} />
        <a href="https://open.spotify.com/episode/2NbinIMcpjlXI2aSq0QIbu" target="_new" rel="noopener noreferrer"><img className="podcasts-icon" alt="Listen on Spotify" src="/images/podcasts_spotify.svg" /></a>
        <p className="small left">2B0 CTO and co-founder Steve Peer speaks with COO Colin Bell and Digital Marketing Coordinator Tyree Quintal from <a href="https://recycle-smart.com/" target="_new" rel="noopener noreferrer">RecycleSmart</a> about developing and deploying the 2B0 recycling robot and the benefits of automated home/office recycling.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>

        <img className="podcasts" alt="Clearview Podcast" src={"/images/podcast_clearview." + imgExt} />
        <a href="https://open.spotify.com/episode/7aIJYOcI2AuhDQ8X0VadNR" target="_new" rel="noopener noreferrer"><img className="podcasts-icon" alt="Listen on Spotify" src="/images/podcasts_spotify.svg" /></a>
        <p className="small left">2B0 CTO and co-founder Steve Peer speaks with Brett Kistler from <a href="https://clearview.team/" target="_new" rel="noopener noreferrer">Clearview</a> about the challenges of recycling and exciting opportunities provided by home and office-based recycling robots.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>

      </div>
    </div>
  );
}
