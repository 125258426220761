import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import AppContext from "../context/AppContext";
import Globe3D from "../components/Globe3D";

export default function AboutUs() {
  const context = useContext(AppContext);

  const gotoIR = () => {
    document.location = "/investor_portal";
  };

  const imgExt = (context.isSafari) ? "jpg" : "webp";
  const imgExt2 = (context.isSafari) ? "png" : "webp";

  return (
    <div className="App-content">

      <Helmet>
        <title>About Us</title>
        <link rel="canonical" href="https://2b0.io/about_us" />
        <meta name="description" content="2B0 was founded in 2019 with the goal of developing a recycling unit that would automatically simplify the process of recycling." />
      </Helmet>

      <section className="App-section">
      <Globe3D flagWireframe={true} />
      <div className="section-top-padding"/>
      <div id="aboutus">
        <h1>About&nbsp;<code>2B0</code></h1>
        <p>&nbsp;</p>
        <p className="left"><span className="small">Founded in 2019,&nbsp;<code>2B0</code>&nbsp;is profoundly innovative, <a href="https://patents.google.com/patent/US9821316B2/ko" target="_new" rel="noopener noreferrer">patented technology</a> that integrates unique solutions and services for residential, municipal and commercial applications. By reducing friction to recycle materials while monitoring and adjusting product consumption, 2B0's intelligent stack will allow customers to be informed and rewarded using Sustainability as a Platform (SusP).</span></p>
        <p>&nbsp;</p>
        <p className="left"><span className="small">The cornerstone of&nbsp;<code>2B0</code>&nbsp;is the AI-driven,&nbsp;<code>2B0</code>&nbsp;<a href="/recycling_robot">IoT recycling robot</a> that automatically sorts and processes recyclable materials. Consumers simply put their recyclables into the unit which then uses AI to automatically sense the material and process it accordingly.</span></p>
        <p>&nbsp;</p>
        <p className="left"><span className="small"><code>2B0</code>’s connected applications gather real-time data for analysis, suggesting methods for reducing waste with the ultimate goal:<br/><strong>To be zero</strong></span></p>
      </div>
      </section>
      <section className="App-section">
        <div className="section-top-padding"/>
        <div className="section-top-padding"/>

        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>Awards and Recognition</h1>
        <p>&nbsp;</p>
        <h2>XTC Extreme Tech Challenge<br/>April 2021: Fourth Place</h2>
        <img className="media-awards" alt="Extreme Tech Competition" src={"/images/award_xtc." + imgExt2} />
        <p>&nbsp;</p>
        <h2>Silicon Valley Unicorn Battle<br/>February 2021: Fifth Place</h2>
        <img className="media-awards" alt="Unicorn Battle" src={"/images/award_unicorn." + imgExt2} />
        <p>&nbsp;</p>
        <a href="https://www.producthunt.com/posts/2b0-io-to-be-zero-iot-recycling-robot?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-2b0-io-to-be-zero-iot-recycling-robot" target="_blank" rel="noreferrer"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=295387&theme=light" alt="2B0.io (To be zero) IoT Recycling Robot - Home/Office/Retail recycling made simple and rewarding | Product Hunt" style={{width: '250px', height: '54px'}} width="250" height="54" /></a>

        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h1>Founders</h1>
        <p>&nbsp;</p>
        <img className="media-kit-thumb" alt="Milorad Arsovic" src={"/images/profile_milorad." + imgExt} />
        <p>&nbsp;</p>
        <p className="left"><span className="small"><strong>Milorad Arsovic, CEO, Inventor</strong><br/>A successful serial entrepreneur, Milorad has a deep passion for reversing the negative impacts of human behavior to our planet, including working against climate change, environmental pollution and unsustainable consumption habits.</span></p>
        <p>&nbsp;</p>
        <p className="left"><span className="small">In 2017, Milorad <a href="https://patents.google.com/patent/US9821316B2/ko" target="_new" rel="noopener noreferrer">patented a solution</a> to greatly reduce the impact of consumer recyclable waste. The&nbsp;<a href="/recycling_robot"><code>2B0</code>&nbsp;recycling unit</a>, the culmination of his vision, is now in development.
          <br/><a href="https://www.linkedin.com/in/milorad-arsovic-04680962/" target="_new" rel="noopener noreferrer">&raquo;&raquo; Milorad Arsovic on LinkedIn</a>
        </span></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <img className="media-kit-thumb" alt="Stephen Peer" src={"/images/profile_steve." + imgExt} />
        <p>&nbsp;</p>
        <p className="left"><span className="small"><strong>Stephen Peer, CTO</strong><br/>Stephen has achieved great success with sustainability and technology startups, having mentored new companies into market leadership where they were featured prominently in the Wall Street Journal, the New York Times, Good Morning America, ABC/CBS News and Wired Magazine.</span></p>
        <p>&nbsp;</p>
        <p className="left"><span className="small">Stephen’s philosophy for product development encompasses simple workflows, attractive design, clear value propositions and inspirational features.
          <br/><a href="https://www.linkedin.com/in/steve-2b0/" target="_new" rel="noopener noreferrer">&raquo;&raquo; Stephen Peer on LinkedIn</a>
        </span></p>
        <p>&nbsp;</p>
        <h1>Startup Opportunity</h1>
        <p>&nbsp;</p>
        <p className="left"><span className="small">Having raised two pre-seed rounds for a total of $500,000,&nbsp;<code>2B0</code>&nbsp;is now in the seed round of venture financing. For more information or to request an investor kit, please use the link below:</span></p>
        <p>&nbsp;</p>
        <button className="primary" onClick={gotoIR}>Investor Portal</button>
        <p>&nbsp;</p>
      </section>
    </div>
  );
}
