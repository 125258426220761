import React from "react";
import {Helmet} from "react-helmet";

const imgInvest1 = "/images/investor1.png";

export default function InvestorRelations() {
  const gotoContact = () => {
    document.location = "/contact_us";
  };

  return (
    <div className="App-content">

      <Helmet>
        <title>Investor Portal</title>
        <link rel="canonical" href="https://2b0.io/investor_portal" />
        <meta name="description" content="2B0 is profoundly innovative, patented technology that integrates unique solutions and services for residential lifestyles and commercial applications." />
      </Helmet>

      <div className="section-top-padding"/>
      <p>&nbsp;</p>
      <h1>Investor Portal</h1>
      <img className="investor-slide" alt="Investor Slide" src={imgInvest1} />
      <p className="left"><span className="small"><code>2B0</code>&nbsp;is profoundly innovative, <a href="https://patents.google.com/patent/US9821316B2/ko" target="_new" rel="noopener noreferrer">patented technology</a> that integrates unique solutions and services for residential lifestyles and commercial applications.</span></p>
      <p>&nbsp;</p>
      <p className="left"><span className="small">By reducing friction to recycle materials while monitoring and adjusting product consumption, <code>2B0</code>'s intelligent stack will allow customers to be informed and rewarded using Sustainability as a Platform (SusP).</span></p>
      <p>&nbsp;</p>
      <button className="primary" onClick={gotoContact}>Request Investor Kit</button>
      <p>&nbsp;</p>
      <p className="left"><span className="small">Investor email inquiries: <a href="mailto:investor@2b0.io">investor@2b0.io</a></span></p>
      <p>&nbsp;</p>
      <p className="left"><span className="small">
        See&nbsp;<code>2B0</code>&nbsp;featured <a href="/media_room">in the news</a><br/>
        Follow&nbsp;<code>2B0</code>&nbsp; on <a href="https://www.crunchbase.com/organization/2b0" target="_new" rel="noopener noreferrer">Crunchbase</a><br/>
        Visit&nbsp;<code>2B0</code>&nbsp; on <a href="https://angel.co/company/2b0" target="_new" rel="noopener noreferrer">Angel.co</a>
      </span></p>
      <p>&nbsp;</p>
      <h2>Timeline</h2>
      <table>
        <tbody>
        <tr>
          <td><strong>2017</strong></td>
          <td>&#8226; Patents are granted</td>
        </tr>
        <tr>
          <td><strong>2018</strong></td>
          <td>&#8226; Market research<br/>
            &#8226; Product technology research<br/>
            &#8226; Design product prototypes<br/>
          </td>
        </tr>
        <tr>
          <td><strong>2019</strong></td>
          <td>&#8226; Company is incorporated<br/>
            &#8226; Team is assembled<br/>
            &#8226; Advanced product design<br/>
            &#8226; Founders investment round: $250k
          </td>
        </tr>
        <tr>
        <td><strong>2020</strong></td>
          <td>&#8226; <code>2B0</code> brand identity established<br/>
            &#8226;  Social media accounts initiated<br/>
            &#8226;  Mobile app development initiated<br/>
            &#8226;  Marketing plan initiated<br/>
            &#8226;  Partnerships established<br/>
            &#8226;  IoT robot prototype designed and tested<br/>
            &#8226;  Next founders investment round: $250k
          </td>
        </tr>
        <tr>
          <td><strong>2021</strong></td>
          <td>&#8226; IoT robot designed, engineered and developed<br/>
            &#8226;  Initial robot deployment and testing<br/>
            &#8226;  Mobile app released<br/>
            &#8226;  Supply chain established<br/>
            &#8226;  Cloud-based technology architecture finalized<br/>
            &#8226;  Integration into test markets begins<br/>
            &#8226;  Data analysis and correlation
          </td>
        </tr>
        <tr>
          <td><strong>2022</strong></td>
          <td>&#8226;  Widespread marketing and promotion<br/>
            &#8226;  New markets established<br/>
            &#8226;  Manufacturing scales and intensifies<br/>
            &#8226;  Advanced data analysis and correlated<br/>
            &#8226;  Continual cycles of improvements
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}
