import styled from 'styled-components';

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: top;
  background: #444444;
  height: 100vh;
  width: 75%;
  text-align: right;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 999998;
  transition: transform 0.5s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(-100%)' : 'translateX(0)'};
  opacity: ${({ open }) => open ? '100%' : '0'};
  
  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #FFFFFF;
    text-decoration: none;
    transition: color 0.3s linear;
    
    @media (max-width: 991px) {
      font-size: 0.8rem;
      text-align: right;
    }

    &:hover {
      color: #FFFFFF;
    }
  }
`;
