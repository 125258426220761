import React from "react";

export default function UnitBullets() {
  return (
    <div className="text-content">
      <span className="small">
        <ul>
          <li>Simple, clean recycling using automation</li>
          <li>Material recognition with AI auto-sorting</li>
          <li>IoT driven robotics</li>
          <li>Material grinding reduces volume 15:1</li>
          <li>Ground material is vacuum packed</li>
          <li>Gamified experience with reward incentives</li>
          <li>Big data collection and analysis</li>
          <li>Mobile app helps consumers change their purchasing habits</li>
        </ul>
      </span>
    </div>
  );
}
