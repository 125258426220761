import React, {useEffect, useState, useContext} from "react";
import * as THREE from "three";
import AppContext from "../context/AppContext";

export default function Globe3D({flagWireframe}) {
  const [renderFlag, setRenderFlag] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const context = useContext(AppContext);

  useEffect(() => {
    const isSafari = (navigator.userAgent.toLowerCase().indexOf('safari') >= 0) && (navigator.userAgent.toLowerCase().indexOf('chrome') < 0);
    const imgExt = (isSafari) ? "jpg" : "webp";
    const imgEarth = "/images/" + context.mobilePrefix + "earth." + imgExt;
    
    if (!renderFlag || (windowWidth !== window.innerWidth)) {
      if (windowWidth !== window.innerWidth) {
        document.getElementById("animation").innerHTML = "";
        setWindowWidth(window.innerWidth);
      }
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(7, window.innerWidth / window.innerHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer();
      const ballMat = new THREE.MeshStandardMaterial({
        color: 0xffffff,
        roughness: 0.5,
        metalness: 1.0
      });
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load(imgEarth, function (map) {
        map.anisotropy = 4;
        map.encoding = THREE.sRGBEncoding;
        ballMat.map = map;
        ballMat.needsUpdate = true;
      });
      textureLoader.load(imgEarth, function (map) {
        map.anisotropy = 4;
        map.encoding = THREE.sRGBEncoding;
        ballMat.metalnessMap = map;
        ballMat.needsUpdate = true;
      });
      const theWidth = window.innerWidth;
      const theHeight = window.innerHeight * .95;
      renderer.setSize(theWidth, theHeight);
      renderer.setClearColor(0x151617, 1);
      document.getElementById("animation").appendChild(renderer.domElement);

      const geometry = new THREE.SphereBufferGeometry(50, 50, 50);
      let sphere;
//      if (flagWireframe) {
//        const wireframe = new THREE.WireframeGeometry(geometry);
//        sphere = new THREE.LineSegments( wireframe );
//        sphere.material.depthTest = true;
//        sphere.material.opacity = 0.25;
//        sphere.material.transparent = true;
//      } else {
      sphere = new THREE.Mesh(geometry, ballMat);
      sphere.material.depthTest = false;
      sphere.material.opacity = 1;
      sphere.material.transparent = false;
      //     }

      scene.add(sphere);
      let bulbLight;
      if (flagWireframe) {
        bulbLight = new THREE.PointLight(0xffee88, 16, 200, 0);
      } else {
        bulbLight = new THREE.PointLight(0xffee88, 4, 100, 0);
      }
      bulbLight.position.set(0, 0, 1000);
      bulbLight.castShadow = true;
      scene.add(bulbLight);
      //const hemiLight = new THREE.HemisphereLight( 0xddeeff, 0x0f0e0d, 0.02 );
      //scene.add( hemiLight );

      camera.position.z = 700;
      if (flagWireframe) {
        camera.position.y = -74;
      } else {
        camera.position.y = -40;
      }
      const animate = function () {
        requestAnimationFrame(animate);
        sphere.rotation.x += 0.005;
        sphere.rotation.y += 0.005;
        renderer.render(scene, camera);
        window.setTimeout(100);
      };
      setRenderFlag(true);
      animate();
    }
  }, [context.mobilePrefix, windowWidth, flagWireframe, renderFlag]);

  return (<div id="animation"/>);
}
