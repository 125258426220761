import React from 'react';
import { Link } from 'react-router-dom';
import { bool } from 'prop-types';
import { StyledMenu } from '../styles/Menu.styled';

const Menu = ({ open, setOpen }) => {
  return (
      <StyledMenu open={open}>
        <Link to="/" onClick={() => {setOpen(!open); document.location = "/";}}>
          Home
        </Link>
        <Link to="/recycling_robot" onClick={() => setOpen(!open)}>
          The&nbsp;<code>2B0</code>&nbsp;Recycling Robot
        </Link>
        <Link to="/investor_portal" onClick={() => setOpen(!open)}>
          Investor Portal
        </Link>
        <Link to="/media_room" onClick={() => setOpen(!open)}>
          Media Room
        </Link>
        <Link to="/podcasts" onClick={() => setOpen(!open)}>
          Podcasts
        </Link>
        <Link to="/mobile_app" onClick={() => setOpen(!open)}>
          Mobile App
        </Link>
        <Link to="/about_us" onClick={() => setOpen(!open)}>
          About&nbsp;<code>2B0</code>
        </Link>
        <Link to="/contact_us" onClick={() => setOpen(!open)}>
          Contact Us
        </Link>
      </StyledMenu>
  )
}
Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
