import React, { useContext } from "react";
import {Helmet} from "react-helmet";
import AppContext from "../context/AppContext";

export default function MediaRoom() {
  const context = useContext(AppContext);
  const imgExt = (context.isSafari) ? "png" : "webp";
  const imgExt2 = (context.isSafari) ? "jpg" : "webp";

  return (
    <div className="App-content">

      <Helmet>
        <title>Media Room</title>
        <link rel="canonical" href="https://2b0.io/media_room" />
        <meta name="description" content="Media kit resources and links to 2B0 in the news" />
      </Helmet>

      <div className="section-top-padding"/>
      <p>&nbsp;</p>
      <h1>Media Room</h1>
      <p><span className="small">Please direct media inquiries to: <a href="mailto:media@2b0.io">media@2b0.io</a></span></p>
      <p>&nbsp;</p>
      <h2><code>2B0</code>&nbsp; in the News</h2>

      <p className="left"><span className="small">December 5, 2021</span></p>
      <p className="left"><span className="small"><strong>12 Top Consumer Electronics Startups in Menlo Park – The Future of Consumer Electronics</strong></span></p>
      <div className="media-mentions">
        <div className="in-the-news">
          <p className="left"><span className="small"><a href="https://businessofshopping.com/12-top-consumer-electronics-startups-in-menlo-park-the-future-of-consumer-electronics/" target="_new" rel="noopener noreferrer">&raquo;&raquo; BOS</a></span></p>
        </div>
      </div>
      <p>&nbsp;</p>

      <p className="left"><span className="small">May 13, 2021</span></p>
      <p className="left"><span className="small"><strong>Radical Recycling Robots from 2BØ (To Be Zero) Are Coming to Your Home and Office</strong></span></p>
      <div className="media-mentions">

        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://www.marketwatch.com/press-release/radical-recycling-robots-from-2b0-to-be-zero-are-coming-to-your-home-and-office-2021-05-13?siteid=bigcharts&dist=bigcharts&tesla=y" target="_new" rel="noopener noreferrer">&raquo;&raquo; MarketWatch</a>
          </span></p>
        </div>
        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://www.benzinga.com/pressreleases/21/05/b21099098/radical-recycling-robots-from-2b0-to-be-zero-are-coming-to-your-home-and-office" target="_new" rel="noopener noreferrer">&raquo;&raquo; Benzinga</a>
          </span></p>
        </div>
        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://www.businesswire.com/news/home/20210513005348/en/Radical-Recycling-Robots-from-2B0-To-Be-Zero-Are-Coming-to-Your-Home-and-Office" target="_new" rel="noopener noreferrer">&raquo;&raquo; BusinessWire</a>
          </span></p>
        </div>
        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://money.yahoo.com/radical-recycling-robots-2b0-zero-080000042.html" target="_new" rel="noopener noreferrer">&raquo;&raquo; Yahoo Money</a>
          </span></p>
        </div>
        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://www.financialbuzz.com/radical-recycling-robots-from-2b0-to-be-zero-are-coming-to-your-home-and-office/" target="_new" rel="noopener noreferrer">&raquo;&raquo; Financial Buzz</a>
          </span></p>
        </div>
        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://techtodaynewspaper.com/radical-recycling-robots-from-2b0-to-be-zero-are-coming-to-your-home-and-office/" target="_new" rel="noopener noreferrer">&raquo;&raquo; Tech Today</a>
          </span></p>
        </div>
        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://www.marketscreener.com/news/latest/Radical-Recycling-Robots-from-2B0-To-Be-Zero-Are-Coming-to-Your-Home-and-Office--33246996/" target="_new" rel="noopener noreferrer">&raquo;&raquo; Market Screener</a>
          </span></p>
        </div>
      </div>

      <p className="left"><span className="small">March 28, 2021</span></p>
      <p className="left"><span className="small"><strong>23 Best Green Consumer Goods Startups Worth A Follow In 2021</strong></span></p>
      <div className="media-mentions">
        <div className="in-the-news">
          <p className="left"><span className="small"><a href="https://startupill.com/23-best-green-consumer-goods-startups-worth-a-follow-in-2021/" target="_new" rel="noopener noreferrer">&raquo;&raquo; Startup Pill</a></span></p>
        </div>
      </div>
      <p>&nbsp;</p>

      <p className="left"><span className="small">March 18, 2021</span></p>
      <p className="left"><span className="small"><strong>2BØ is Selected to Compete in the Silicon Valley Unicorn Startup Battle</strong></span></p>
      <div className="media-mentions">
        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://recyclinginside.com/2b0-is-selected-to-compete-in-the-silicon-valley-unicorn-startup-battle/" target="_new" rel="noopener noreferrer">&raquo;&raquo; Recycling Inside Magazine</a>
          </span></p>
        </div>
      </div>

      <p className="left"><span className="small">February 11, 2021</span></p>
      <p className="left"><span className="small"><strong>2BØ is Selected to Compete in the Silicon Valley Unicorn Startup Battle</strong></span></p>
      <div className="media-mentions">
        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://www.benzinga.com/pressreleases/21/02/p19607079/2b0-is-selected-to-compete-in-the-silicon-valley-unicorn-startup-battle" target="_new" rel="noopener noreferrer">&raquo;&raquo; Benzinga</a>
          </span></p>
        </div>
        <div className="in-the-news">
          <p className="left"><span className="small">
              <a href="https://www.wfmz.com/news/pr_newswire/pr_newswire_business/2b0-is-selected-to-compete-in-the-silicon-valley-unicorn-startup-battle/article_36c722c8-d70f-5346-9bf7-2f689fc880d2.html" target="_new" rel="noopener noreferrer">&raquo;&raquo; WFMZ-TV</a>
          </span></p>
        </div>
      </div>

      <p className="left"><span className="small">December 28, 2020</span></p>
      <p className="left"><span className="small"><strong>65 Of The Best Waste Managment Companies Worth A Follow And Potential Investment</strong></span></p>
      <div className="media-mentions">
        <div className="in-the-news">
          <p className="left"><span className="small"><a href="https://startupill.com/65-of-the-best-waste-managment-companies-worth-a-follow-and-potential-investment/" target="_new" rel="noopener noreferrer">&raquo;&raquo; Startup Pill</a></span></p>
        </div>
      </div>
      <p>&nbsp;</p>

      <p className="left"><span className="small">November 23, 2020</span></p>
      <p className="left"><span className="small"><strong>32 Inspirational Recycling Companies To Follow and Learn From</strong></span></p>
      <div className="media-mentions">
        <div className="in-the-news">
          <p className="left"><span className="small"><a href="https://startupill.com/32-inspirational-recycling-companies-to-follow-and-learn-from/" target="_new" rel="noopener noreferrer">&raquo;&raquo; Startup Pill</a></span></p>
        </div>
      </div>
      <p>&nbsp;</p>

      <p>&nbsp;</p>
      <h2><code>2B0</code>&nbsp; Press Kit</h2>
      <p className="underlined"><span className="small"><strong>Downloadable High Resolution Images</strong></span></p>
      {context.isMobile ?
        <p><span className="small">Touch the asset and then save it to your mobile device.</span></p>
        :
        <p><span className="small">Right-click each asset below and select "Save link as".</span></p>
      }
      <div className="full-width">
        <div>
          <a href="/media/unit_insitu_06_14000x5250.png"><img className="media-kit-thumb-wide" alt="2B0 Recycle Robot in a Home Environment" src={"/media/unit_insitu_06_thumb." + imgExt} /></a>
          <p><span className="tiny"><code>2B0</code> Recycling Robot in a Home<br/>PNG format<br />14,000px x 5,250px &mdash; 54.5mb</span></p>
        </div>
      </div>
      <div className="full-width">
        <div>
          <a href="/media/unit_insitu_04_10000x5000.png"><img className="media-kit-thumb-wide" alt="2B0 Recycle Robot in an Office Environment" src={"/media/unit_insitu_04_thumb." + imgExt} /></a>
          <p><span className="tiny"><code>2B0</code> Recycling Robot in an Office<br/>PNG format<br />10,000px x 5,000px &mdash; 36.1mb</span></p>
        </div>
      </div>
      <div className="full-width">
        <div>
          <a href="/media/unit_insitu_05_6000x4000.png"><img className="media-kit-thumb-wide" alt="2B0 Recycle Robot in an Office Environment" src={"/media/unit_insitu_05_thumb." + imgExt} /></a>
          <p><span className="tiny"><code>2B0</code> Recycling Robot in an Office<br/>PNG format<br />6,000px x 4,000px &mdash; 15.4mb</span></p>
        </div>
      </div>
      <div className="full-width">
        <div>
          <a href="/media/unit_insitu_01_14600x6000.png"><img className="media-kit-thumb-wide" alt="2B0 Recycle Robot in a Home Environment" src={"/media/unit_insitu_01_thumb." + imgExt} /></a>
          <p><span className="tiny"><code>2B0</code> Recycling Robot in a Home<br/>PNG format<br />14,600px x 6,002px &mdash; 19.7mb</span></p>
        </div>
      </div>
      <div className="full-width">
        <div>
          <a href="/media/unit_insitu_02_14600x6000.png"><img className="media-kit-thumb-wide" alt="2B0 Recycle Robot in a Home Environment" src={"/media/unit_insitu_02_thumb." + imgExt} /></a>
          <p><span className="tiny"><code>2B0</code> Recycling Robot in a Home<br/>PNG format<br />16,600px x 5,006px &mdash; 34.8mb</span></p>
        </div>
      </div>
      <div className="full-width">
        <div>
          <a href="/media/unit_insitu_03_14600x6000.png"><img className="media-kit-thumb-wide" alt="2B0 Recycle Robot in a Home Environment" src={"/media/unit_insitu_03_thumb." + imgExt} /></a>
          <p><span className="tiny"><code>2B0</code> Recycling Robot in a Home<br/>PNG format<br />16,000px x 5,804px &mdash; 32.3mb</span></p>
        </div>
      </div>
      <div className="media-kit">
        <div>
          <a href="/media/2B0_logo_1600x1800.png"><img className="media-kit-thumb" alt="2B0 Downloadable Logo" src={"/media/2B0_logo_thumb." + imgExt} /></a>
          <p><span className="tiny"><code>2B0</code> Logo<br/>PNG format<br />1,600px x 1,800px &mdash; 310k</span></p>
        </div>
        <div>
          <a href="/media/2b0_unit_3000x2000.jpg"><img className="media-kit-thumb" alt="2B0 Recycle Robot" src={"/media/2b0_unit_thumb." + imgExt} /></a>
          <p><span className="tiny"><code>2B0</code> Recycling Robot<br/>JPG format<br />3,000px x 2,000px &mdash; 1.4mb</span></p>
        </div>
        <div>
          <a href="/media/bin-after.jpg"><img className="media-kit-thumb" alt="2B0 Processed Aluminum Cans" src={"/media/bin-after_thumb." + imgExt2} /></a>
          <p><span className="tiny"><code>2B0</code> Processed Aluminum Cans<br/>(15:1 Volume Reduction)<br/>JPG format<br />1,359px x 1,359px &mdash; 612k</span></p>
        </div>
      </div>
      <p>&nbsp;</p>
      <p className="underlined"><span className="small"><strong>Text Abstracts</strong></span></p>
      <p><span className="small">Copy and paste the text below.</span></p>
      <p>&nbsp;</p>
      <p className="left"><span className="large"><strong>87 Words</strong></span></p>
      <div className="hightlighted-text">
        <p className="left"><span className="small">2B0 (to be zero) is innovative, patented technology that integrates unique solutions and services for residential, municipal and commercial applications.<br/><br/>
        The cornerstone of 2B0 is the AI-driven, IoT recycling robot that automatically sorts and processes recyclable materials. Consumers simply put their recyclables into the robot which uses AI to automatically sense the material and process it accordingly.<br/><br/>
        By reducing friction to recycle materials while monitoring and adjusting product consumption, 2B0's omnichannel intelligent stack will allow customers to be informed and rewarded using Sustainability as a Platform (SusP).</span></p>
      </div>
      <p>&nbsp;</p>
      <p className="left"><span className="large"><strong>196 Words</strong></span></p>
      <div className="hightlighted-text">
        <p className="left"><span className="small">2B0 (to be zero) is innovative, patented technology that integrates unique solutions and services for residential, municipal and commercial applications.<br/><br/>
The cornerstone of 2B0 is the AI-driven, IOT recycling robot that automatically sorts and processes recyclable materials. Consumers simply put their recyclables into the robot which uses AI to automatically sense the material and process it accordingly. The 2B0 recycling robot encompasses:<br/><br/>
•	Simple, clean recycling using automation<br/>
•	Material recognition with AI auto-sorting<br/>
•	IoT driven robotics<br/>
•	Material grinding reduces volume 15:1<br/>
•	Ground material is vacuum packed for pickup<br/>
•	Gamified experience with reward incentives<br/>
•	Big data collection and analysis<br/>
•	Mobile app helps consumers change their purchasing habits<br/><br/>
By reducing friction to recycle materials while monitoring and adjusting product consumption, 2B0's omnichannel intelligent stack will allow customers to be informed and rewarded using Sustainability as a Platform (SusP).<br/><br/>
2B0 was founded by Milorad Arsovic in 2019. As a successful inventor and serial entrepreneur, Milorad has a deep passion for reversing the negative impacts of human behavior to our planet, including working against climate change, environmental pollution and unsustainable consumption habits.<br/><br/>
Having raised two pre-seed rounds for a total of $500,000, 2B0 is now in the seed round of venture financing.</span></p>
      </div>
    </div>
  );
}
