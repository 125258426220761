import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import AppContext from "../context/AppContext";

export default function TermsOfUse() {
  const context = useContext(AppContext);

  const imgExt = (context.isSafari) ? "jpg" : "webp";

  return (
  <div className="App-content">

    <Helmet>
      <title>Privacy and Terms of Use</title>
      <link rel="canonical" href="https://2b0.io/terms" />
      <meta name="description" content="2B0 website terms of use" />
    </Helmet>

    <div className="section-top-padding"/>
    <h1>Website Terms of Use</h1>
    <p className="left"><span className="small"></span></p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>
  );
}
