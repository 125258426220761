import React, { Suspense, useEffect, useContext } from "react";
import logo from "../logo.svg";
import Globe3D from "../components/Globe3D";
import AppContext from "../context/AppContext";

const UnitComponent = React.lazy(() => import('../components/TheUnit'));
const VideoComponent = React.lazy(() => import('../components/HomeVideo'));

export default function HomePage() {
  const context = useContext(AppContext);

  let extImg = (context.isSafari) ? "jpg" : "webp";
  let imgUnit = "/images/" + context.mobilePrefix + "the_unit." + extImg;
  let unitImg = (<img alt="Consumer Unit" id="home-unit" src={imgUnit} />);
  let imgBackground = "/images/" + context.mobilePrefix + "bg-nature1." + extImg;

  useEffect(() => {
    extImg = (context.isSafari) ? "jpg" : "webp";
    imgUnit = "/images/" + context.mobilePrefix + "the_unit." + extImg;
    unitImg = (<img alt="Consumer Unit" id="home-unit" src={imgUnit} />);
    imgBackground = "/images/" + context.mobilePrefix + "bg-nature1." + extImg;
  });

  const gotoPodcasts = () => {
    document.location = "/podcasts";
  };
  const gotoNews = () => {
    document.location = "/media_room";
  };


  return (
      <div>
        <section className="App-section">
          <Globe3D flagWireframe={false} />
          <div id="homepage">
            <div>
              <button className="tertiary" onClick={gotoNews}>2B0 in the News</button>
              <div/>
              <button className="tertiary" onClick={gotoPodcasts}>Listen to our Podcasts</button>
            </div>
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Our planet is dying. We need&nbsp;<span className="eco"><code>2B0</code>&nbsp;(to be zero)</span>.<br/>
              <span className="small">Zero carbon footprint. Zero plastic. Zero waste.</span>
            </p>
            <a href="#more">
              <svg id="more-arrows">
                <polygon className="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
              </svg>
            </a>
          </div>
        </section>
        <Suspense fallback={unitImg}>
          <a name="more"/><section className="App-section">
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <div className="problem-solution">
            <h2>The Challenges</h2>
            <span className="small"><ul className="problem-solution">
              <li>Sorting recyclables can be cumbersome</li>
              <li>Recycled material has low value</li>
              <li>Recyclables are dumped in landfills</li>
              <li>Consumers have little incentive to recycle</li>
            </ul></span>
          </div>
          <div className="problem-solution bg-nature" style={{ backgroundImage: 'url(' + imgBackground + ')' }}>
            <h2 className="bg-nature">The Solution</h2>
            <span className="small"><ul className="problem-solution bg-nature">
              <li className="bg-nature">Make the recycling experience enjoyable</li>
              <li className="bg-nature">Automate sorting/processing of recyclables</li>
              <li className="bg-nature">Increase the value of recycled material</li>
              <li className="bg-nature">Reward consumers for recycling</li>
              <li className="bg-nature">Help consumers change purchase habits</li>
            </ul></span>
          </div>
          <a href="#unit">
            <svg id="more-arrows">
              <polygon className="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
            </svg>
          </a>
          </section>
          <a name="unit"/><section className="App-section">
          <UnitComponent />
        </section>
          <VideoComponent />
        </Suspense>
      </div>
  );
}
