import React, {useState, useContext, useEffect} from "react";
import {Helmet} from "react-helmet";
import AppContext from "../context/AppContext";
import UnitBullets from "../content/UnitBullets";

export default function RecyclingRobot() {
  const [picnum, setPicnum] = useState(1);
  const context = useContext(AppContext);

  const imgSrc = "/images/" + context.mobilePrefix + "unit" + picnum + ".jpg";
  window.setTimeout(function() {
    let theNum = picnum + 1;
    if (theNum > 4) theNum = 1;
    setPicnum(theNum);
  }, 4000);

  const gotoContact = () => {
    document.location = "/contact_us";
  };

  const imgExt = (context.isSafari) ? "png" : "webp";

  let imgUnitHome = "/images/" + context.mobilePrefix + "insitu_01." + imgExt;
  let imgUnitHome2 = "/images/" + context.mobilePrefix + "insitu_02." + imgExt;
  let imgUnitHome3 = "/images/" + context.mobilePrefix + "insitu_03." + imgExt;
  let imgAfter = "/images/" + context.mobilePrefix + "bin-after.jpg";

  useEffect(() => {
    imgUnitHome = "/images/" + context.mobilePrefix + "insitu_01." + imgExt;
    imgUnitHome2 = "/images/" + context.mobilePrefix + "insitu_02." + imgExt;
    imgUnitHome3 = "/images/" + context.mobilePrefix + "insitu_03." + imgExt;
    imgAfter = "/images/" + context.mobilePrefix + "bin-after.jpg";
  });


  return (
      <div className="App-content">

        <Helmet>
          <title>The 2B0 Recycling Robot</title>
          <link rel="canonical" href="https://2b0.io/recycling_robot" />
          <meta name="description" content="The revolutionary, patented 2B0 recycling robot automatically sorts and processes recyclables into valuable materials and back into the circular economy." />
        </Helmet>

        <div className="section-top-padding"/>
        <p>&nbsp;</p>
        <h1>The&nbsp;<code>2B0</code>&nbsp;Recycling Robot</h1>
        <p>&nbsp;</p>
        <img className="unit-images" alt="2B0 Robot in a home setting" src={imgUnitHome} />
        <p className="left"><span className="small">
          The revolutionary, <a href="https://patents.google.com/patent/US9821316B2/ko" target="_new" rel="noopener noreferrer">patented</a>&nbsp;<code>2B0</code>&nbsp;recycling unit automatically sorts and processes recyclables into valuable materials and back into the <a target="_new" rel="noopener noreferrer" href="https://www.ellenmacarthurfoundation.org/circular-economy/what-is-the-circular-economy">circular economy</a>.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <img className="unit-images" alt="2B0 Robot in a home setting" src={imgUnitHome2} />
        <p className="left"><span className="small">
          Engineered for use in home, offices and retail locations, the <code>2B0</code>&nbsp;recycling robot simplifies recycling while increasing the value of recycled materials.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <img className="unit-images" alt="2B0 Robot in a home setting" src={imgUnitHome3} />
        <p className="left"><span className="small">
          Using the <code>2B0</code>&nbsp; <a href="/mobile_app">mobile app</a>, consumers are rewarded for recycling, while becoming more aware of ways to positively impact their consumption habits.
          </span>
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <div id="before-after">
          <div>
            <h2>BEFORE</h2>
            <div className="img-before-after bins-before" />
          </div>
          <div>
            <h2>AFTER</h2>
            <img className="img-before-after" src={imgAfter} />
          </div>
        </div>
        <p>&nbsp;</p>
        <UnitBullets />
        <p>&nbsp;</p>
        <button className="primary" onClick={gotoContact}>Subscribe to News</button>
        <p>&nbsp;</p>
        <img alt="2B0 Recycle Robot" id="unit_image" className="unit-images" src={imgSrc} />
        <p>&nbsp;</p>
      </div>
  );
}
