import React, { useState, useRef, useEffect } from 'react'
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'
import HomePage from "./pages/Home"
import AboutUs from "./pages/AboutUs"
import RecyclingRobot from "./pages/RecyclingRobot"
import MobileApp from "./pages/MobileApp"
import MediaRoom from "./pages/MediaRoom"
import Podcasts from "./pages/Podcasts"
import InvestorRelations from "./pages/InvestorRelations"
import ContactUs from "./pages/ContactUs"
import TermsOfUse from "./pages/Terms"
import Privacy from "./pages/Privacy"
import Burger from "./components/Burger"
import Menu from "./components/Menu"
import { useOnClickOutside } from "./components/Hook"
import AppContext from "./context/AppContext"
import logo from './logo.svg'
import './App.css'

function App() {
  const [open, setOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [mobilePrefix, setMobilePrefix] = useState("")
  const [isSafari, setIsSafari] = useState(false)
  const node = useRef()
  useOnClickOutside(node, () => setOpen(false))

  const indiLink = "https://www.indiegogo.com/projects/2b0-to-be-zero-recycling-robots-for-home-office/x/27569543#/";
  const gotoHome = () => {
    document.location = "/";
  }

  const gotoFund = () => {
    document.location = indiLink;
  }

  useEffect(() => {
    setIsMobile(window.innerWidth < 992)
    setMobilePrefix(isMobile ? "mobile-" : "" )
    setIsSafari(((navigator.userAgent.toLowerCase().indexOf('safari') >= 0) && (navigator.userAgent.toLowerCase().indexOf('chrome') < 0)))

    function handleResize() {
      setIsMobile(window.innerWidth < 992)
      setMobilePrefix(isMobile ? "mobile-" : "" )
    }
    window.addEventListener('resize', handleResize)
  }, [isMobile])

  return (
    <AppContext.Provider value={
      {
        isMobile,
        mobilePrefix,
        isSafari
      }
    }>
      <div className="App">
        <Router>
          <Switch>
            <Route exact path='/' component={HomePage} />
            <Route path='/recycling_robot' component={RecyclingRobot} />
            <Route path='/investor_portal' component={InvestorRelations} />
            <Route path='/media_room' component={MediaRoom} />
            <Route path='/podcasts' component={Podcasts} />
            <Route path='/mobile_app' component={MobileApp} />
            <Route path='/about_us' component={AboutUs} />
            <Route path='/contact_us' component={ContactUs} />
            <Route path='/privacy' component={Privacy} />
            <Route path='/terms' component={TermsOfUse} />
          </Switch>
        <header className="App-header">
          <div id="menu-section">
            <img onClick={gotoHome} src={logo} className="top-logo" alt="logo" />
            <div className="desktop-menu">
              <Link className="desktop-menu" to="/recycling_robot">The&nbsp;<code>2B0</code>&nbsp;Recycling Unit</Link>
              <Link className="desktop-menu" to="/investor_portal">Investor Portal</Link>
              <Link className="desktop-menu" to="/media_room">Media Room</Link>
              <Link className="desktop-menu" to="/podcasts">Podcasts</Link>
              <Link className="desktop-menu" to="/about_us/">About&nbsp;<code>2B0</code></Link>
              <Link className="desktop-menu" to="/contact_us">Contact Us</Link>
              <Link className="desktop-menu support" onClick={gotoFund} to="/">Support&nbsp;<code>2B0</code>&nbsp;Now</Link>
            </div>
          </div>
          <div className="mobile-menu" ref={node}>
            <div id="fund-banner">
              <div className="secondary" onClick={gotoFund}>Support <code>2B0</code> Now</div>
            </div>
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} />
          </div>
        </header>
          <footer>
            <div className="social">
              <a href="https://www.crunchbase.com/organization/2b0"><img alt="Link to Crunchbase" className="social" src="/images/icon_crunchbase.png" /></a>
              <a href="https://www.linkedin.com/company/2b0/"><img alt="Link to LinkedIn" className="social" src="/images/icon_linkedin.png" /></a>
              <a href="https://angel.co/company/2b0"><img alt="Link to Angel.co" className="social" src="/images/icon_angel.png" /></a>
              <a href="https://www.producthunt.com/posts/2b0-io-to-be-zero-iot-recycling-robot"><img alt="Link to Product Hunt" className="social" src="/images/icon_producthunt.png" /></a>
              <a href="https://twitter.com/2BeZero"><img alt="Link to Twitter" className="social" src="/images/icon_twitter.png" /></a>
              <a href="https://www.facebook.com/2b0.io"><img alt="Link to Facebook" className="social" src="/images/icon_facebook.png" /></a>
              <a href="https://www.instagram.com/2b0.io/"><img alt="Link to Instagram" className="social" src="/images/icon_instagram.png" /></a>
            </div>
            Copyright &copy; 2020-2022. All rights reserved.<br/><code>2B0</code> (To Be Zero) is a trademark of Recycling Units &amp; Micro Waste Management Systems Inc.
            <br/><br/>
            <a href="/privacy">Privacy Policy</a>
            <br/><br/>
            This website uses dark mode to conserve energy.
          </footer>
        </Router>
      </div>
    </AppContext.Provider>
  );
}

export default App;
